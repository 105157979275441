import React, { Component } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer
} from "recharts";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// const data = [
//   {
//     Profundidad: 0,
//     Caudal: 1.1999999999999999555910790149937383830547332763671875,
//   },
//   {
//     Profundidad: 15,
//     Caudal: 1.0500000000000000444089209850062616169452667236328125,
//   },
//   {
//     Profundidad: 30,
//     Caudal: 0.84999999999999997779553950749686919152736663818359375,
//   },
//   {
//     Profundidad: 45,
//     Caudal: 0.59999999999999997779553950749686919152736663818359375,
//   },
//   {
//     Profundidad: 60,
//     Caudal: 0.40000000000000002220446049250313080847263336181640625,
//   },
//   {
//     Profundidad: 75,
//     Caudal: 0.05000000000000000277555756156289135105907917022705078125,
//   },
//   {
//     Profundidad: 80,
//     Caudal: 0,
//   },
// ];

class HanduroPumpGraph extends Component {
 
   render() {
    const { pumpdata } = this.props;
    const data = pumpdata[0].custom_field_json_value;

    return (
    <ResponsiveContainer aspect={2}  >
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 15,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis dataKey="Profundidad" type="number" tickCount={7} >
            <Label value="Profundidad mts" angle={-90} offset={15} position="insideBottomLeft" />
        </YAxis>
        <XAxis dataKey="Caudal" type="number" tickCount={7}  >
            <Label value="m3/h" offset={0} position="bottom" />
        </XAxis>
        <Tooltip />
        <Line
          type="monotone"
          dataKey="Profundidad"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
    );
  }
}

export default HanduroPumpGraph;
