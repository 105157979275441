import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';

import HanduroPumpGraph from './HanduroPumpGraph';

class HanduroPump extends Component {
  state = { pumpdata: {}, isLoading: true };
  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    // const urlApi = "http://127.0.0.1:8000/api/products/" + params.pumpId;
    // const urlApi = 'https://api.sunny-business.com/api/products/' + params.pumpId;
    const urlApi =
      'https://sunnybusiness.suriaenergy.com/api/admin/v1/products/' +
      params.pumpId;
    const getParams = {};
    const response = await axios.get(urlApi);
    this.setState({ pumpdata: response.data, isLoading: false });
  }

  renderDetails(pumpData) {
    return pumpData.map((pumpinfo) => {
      return (
        <div className="row" key={pumpinfo.id}>
          <div className="col-6 bg-dark text-light border">
            {pumpinfo.custom_field_name}
          </div>
          <div className="col-6 border">{pumpinfo.custom_field_value}</div>
        </div>
      );
    });
  }

  render() {
    if (this.state.isLoading) {
      return <div>loading</div>;
    }

    const { product, product_custom_field_values } = this.state.pumpdata;
    const pumpGraphData = _.filter(product_custom_field_values, {
      custom_field_name: 'DataCurvaJSON',
    }); // filtra el campo que contiene los datos de la curva de bombeo: DataCurvaJSON
    const pumpData = _.reject(product_custom_field_values, {
      custom_field_name: 'DataCurvaJSON',
    });

    console.log(pumpData);

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{product}</h3>
          </div>
        </div>
        {this.renderDetails(pumpData)}

        <div className="row mt-3">
          <div className="col-12">
            <HanduroPumpGraph pumpdata={pumpGraphData} />
          </div>
        </div>
      </div>
    );
  }
}

export default HanduroPump;
