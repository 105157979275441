import React, { Component } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';

import HanduroPump from './HanduroPump';

class App extends Component {
  render() { 
    return ( 
      <BrowserRouter>
        <Route path="/:pumpId" exact component={HanduroPump} />
      </BrowserRouter>
     );
  }
}
 
export default App;